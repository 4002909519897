<template>
  <div
    :aria-label="draftType === 'board' ? $t('Page Drawer') : $t('Slide Drawer')"
    class="page-drawer"
    :class="{ 'page-drawer--animated': editorDrawersAreAnimated }"
    role="region"
    tabindex="-1"
  >
    <div
      class="page-drawer__actions"
      v-if="mode === 'EDIT'"
    >
      <NebulaButton
        icon-left="circle-plus"
        size="s"
        :text="addButton.text"
        :de-tracking-name="addButton.tracking"
        type="flat"
        @click="openAddSlideModal"
      />
      <NebulaButton
        v-if="draftIsLesson"
        icon-left="add-asset"
        :is-disabled="!canAddSection"
        size="s"
        :text="$t('Add Section')"
        type="flat"
        @click="onAddSection"
      />
    </div>
    <div class="page-drawer__subdrawer">
      <PageList
        :expanded="expanded"
        :mode="mode"
      />
    </div>
  </div>
</template>

<script>
import { first, sortBy } from 'lodash-es';
import { NebulaButton } from '@discoveryedu/nebula-components';
import { v4 as uuid } from 'uuid';
import { mapActions, mapState } from 'pinia';
import PageList from '@/components/navigation/PageList.vue';
import * as types from '@/lib/constants/store';
import {
  useBulkActionsStore,
  useEditorStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'PageDrawer',
  components: {
    NebulaButton,
    PageList,
  },
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useEditorStore, [
      'draft',
      'currentPage',
      'draftHasTEIErrors',
      'draftIsLesson',
      'draftType',
      'pagesById',
      'sectionsById',
      'editorDrawersAreAnimated',
      'pagesSorted',
    ]),
    ...mapState(useBulkActionsStore, [
      'bulkActionPageIds',
    ]),
    canAddSection() {
      const bulkActionPages = this.bulkActionPageIds
        .map((id) => this.pagesById[id])
        .filter((page) => page);
      const bulkPagesSorted = sortBy(bulkActionPages, 'sort_index');
      const firstPage = first(bulkPagesSorted);
      if (!firstPage) return false;
      const firstPageIndex = this.pagesSorted.findIndex((p) => p.id === firstPage.id);
      if (firstPageIndex === -1) return false;
      return bulkPagesSorted.every((bulkPage, bulkPageIndex) => {
        const sortedPage = this.pagesSorted[firstPageIndex + bulkPageIndex];
        /*
          Ensure that pages being added to the section are in consecutive order
          and are not already in a section.
        */
        return !sortedPage.options?.group_id && sortedPage.id === bulkPage.id;
      });
    },
    addButton() {
      const isBoard = this.draftType === 'board';
      return {
        text: isBoard ? this.$t('Add Page') : this.$t('Add Slide'),
        tracking: isBoard ? 'studio_page-drawer_add-page-button' : 'studio_page-drawer_add-slide-button',
      };
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.UPDATE_PAGE_OPTIONS,
      types.ADD_SECTION,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    onAddSection() {
      const addedSection = {
        id: uuid(),
        name: '',
        pacing_minutes: 0,
      };
      this[types.ADD_SECTION]({
        addedSection,
        pageIds: this.bulkActionPageIds,
      });
    },
    openAddSlideModal() {
      // If we have TEI errors, moving to a new page could overwrite changes. Confirm first
      if (this.draftHasTEIErrors) {
        this[types.OPEN_MODAL]({
          type: 'TEIErrorModal',
          successCallback: () => {
            this[types.OPEN_MODAL]({
              type: 'AddSlideModal',
            });
          },
        });
        return;
      }

      this[types.OPEN_MODAL]({
        type: 'AddSlideModal',
      });
    },
  },
};
</script>

<style lang="stylus">
.page-drawer {
  background-color: $nebula-color-platform-interface-1100;
  color: $nebula-color-white;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -9;

  &--animated {
    transition: $nebula-transition-default;
  }

  &__actions {
    background-color: $nebula-color-white;
    display: flex;
    padding: $nebula-space-1x $nebula-space-2x;
  }

  &__actions { // .page-drawer__actions
    .comet-button:last-child {
      margin: 0;
    }
  }

  &__subdrawer { // .page-drawer__subdrawer
    display: flex;
  }
}
</style>
