<template>
  <header
    class="top-nav"
  >
    <div
      class="role-alert"
      v-if="actionTaken"
      role="alert"
      aria-live="assertive"
    >
      {{ action }}
    </div>
    <div
      class="top-nav__page-drawer-overlay"
      v-if="topNavDrawerIsOpen"
      @click="onPageDrawerOverlayClick"
    />
    <div
      class="top-nav__contents"
      :class="{ 'top-nav__contents--no-shadow': hideNavShadow }"
    >
      <a
        v-if="showPrevPageButton"
        @click="goToPrevPage"
        class="top-nav__prev-page-button"
        de-tracking-name="studio_top-nav_back-button"
      >
        <NebulaIcon
          :symbol-id="backArrowIcon"
        />
      </a>
      <!-- DE logo -->
      <a
        :href="homeUrl"
        class="top-nav__de-link"
        de-tracking-name="studio_top-nav_de-home-icon-link"
        v-if="!isSmallerWindow && !isTechbookArtifact"
      >
        <img
          src="@/assets/de-logo-black.png"
          :alt="$t('Discovery Education')"
          class="top-nav__de-icon"
        >
      </a>
      <!-- Studio logo -->
      <a
        v-if="!isMobileOrTablet() && !isSmallerWindow && !isTechbookArtifact"
        href="/"
        class="top-nav__studio-link"
        de-tracking-name="studio_top-nav_studio-home-icon-link "
        :aria-label="$t('Studio Home')"
      >
        <div class="top-nav__page-title">{{ $t('Studio') }}</div>
      </a>

      <!-- NOTE: This is hidden until ORION-3721, when the back link url will be available -->
      <template v-if="showBoardOptions">
        <BoardOptionsPanel
          ref="boardOptionsPanel"
          :deTrackingName="'board-options-toggle'"
          :deTrackingValue="isBoardOptionsPanelOpen
            ? 'studio_top-nav_board-options-toggle_open'
            : 'studio_top-nav_board-options-toggle_close'"
          :is-interactive="!currentModalCanvasId"
          :mode="mode"
          @panel-open="onBoardOptionsPanelOpen"
          :restricted="isSmallerWindow"
        />
        <PageHeader
          v-if="!currentModalCanvasId"
          @togglePageDrawer="onTogglePageDrawer"
          :page-drawer-expanded="topNavDrawerIsOpen"
        />
      </template>

      <div class="top-nav__actions">
        <template v-if="!draftRestoreEnabled && !currentModalCanvasId">
          <CollaboratorDisplay
            v-if="showCollaboratorDisplay && !isSmallerWindow"
            :owner-id="draft.owner_guid"
            :owner-first-name="draft.owner_first_name"
            :owner-last-name="draft.owner_last_name"
            :shares="draft.shares"
            :connected-users="connectedUsers"
          />
          <NebulaButton
            v-if="showFullscreenButtonInHeader && !isSmallerWindow"
            class="top-nav__fullscreen-button"
            de-tracking-name="studio_top-nav_fullscreen-button"
            size="s"
            type="flat"
            :text="fullscreenText"
            @click="showFullscreen"
          >
            <template #slotIconLeft>
              <NebulaIcon
                size="s"
                symbol-id="fullscreen"
              />
              <span
                v-if="mode === 'VIEW'"
                class="comet-screenreader-only"
              >
                {{ $t('Show Full Screen') }}
              </span>
            </template>
          </NebulaButton>
          <NebulaButton
            v-if="showPublishButton"
            data-click-name="Publish"
            data-click-type="Studio Top Navigation"
            :data-click-experience="experimentCopyJan"
            :data-click-asset-id="assetId"
            de-tracking-name="studio_top-nav_publish-button"
            @click="openPublishModal"
            size="s"
            :text="$t('Publish')"
          />
          <NebulaButton
            v-if="showSaveAndExitButton"
            data-click-name="Save and Exit"
            data-click-type="Studio Top Navigation"
            :data-click-experience="experimentCopyJan"
            :data-click-asset-id="assetId"
            de-tracking-name="studio_top-nav_save-and-exit-button"
            @click="handleSaveAndExitClick"
            size="s"
            :text="$t('Save & Exit')"
            class="top-nav__assign-button"
          >
            <template #slotIconRight>
              <NebulaIcon
                size="s"
                symbol-id="arrow-right-circle"
              />
            </template>
          </NebulaButton>
          <NebulaButton
            v-if="showCopyButtonInHeader && !isMobileOrTablet()"
            :data-click-name="isExperimentCopyJanVariationControl ? 'Customizing' : 'Copy'"
            data-click-type="Studio Top Navigation"
            :data-click-experience="experimentCopyJan"
            :data-click-asset-id="assetId"
            size="s"
            type="flat"
            :text="$t('Duplicate')"
            @click="openCopyCreationModal"
          >
            <template #slotIconLeft>
              <NebulaIcon
                size="s"
                symbol-id="copy"
              />
            </template>
          </NebulaButton>
          <NebulaButton
            v-if="showAssignButton"
            data-click-name="Assign"
            data-click-type="Studio Top Navigation"
            :data-click-experience="experimentCopyJan"
            :data-click-asset-id="assetId"
            de-tracking-name="studio_top-nav_assign-button"
            size="s"
            :text="$t('Assign')"
            @click="openConfirmAssignModal"
            class="top-nav__assign-button"
          >
            <template #slotIconRight>
              <NebulaIcon
                size="s"
                symbol-id="arrow-right-circle"
              />
            </template>
          </NebulaButton>
          <NebulaButton
            v-if="showEmbedButton"
            data-click-name="Embed"
            data-click-type="Studio Top Navigation"
            :data-click-experience="experimentCopyJan"
            :data-click-asset-id="assetId"
            size="s"
            :text="$t('Embed')"
            @click="embedAsset"
            class="top-nav__embed-button"
          />
          <template v-if="showSubmitButtons">
            <div
              v-if="showMarkAsComplete || showCompleted"
              aria-live="polite"
            >
              <NebulaButton
                v-if="showMarkAsComplete"
                @click="markAssignmentAsComplete"
                size="s"
                :is-disabled="markingAssignmentComplete"
                :text="$t('Mark as Complete')"
              />
              <NebulaButton
                v-if="showCompleted"
                size="s"
                is-disabled
                :text="$t('Completed')"
              />
            </div>
            <NebulaButton
              v-if="showSubmitAssignment"
              @click="openSubmitAssignmentModal"
              size="s"
              :text="$t('Submit Assignment')"
              class="top-nav__submit-button"
            >
              <template #slotIconRight>
                <NebulaIcon
                  size="s"
                  symbol-id="arrow-right-circle"
                />
              </template>
            </NebulaButton>
            <NebulaButton
              v-if="showViewResults"
              size="s"
              :link="viewResultsUrl"
              :text="$t('View Results')"
            />
          </template>
          <NebulaDropdown
            v-if="showMoreOptionsMenu"
            :button-aria-label="$t('More Options')"
            button-type="ellipses"
            class="top-nav__more-option-dropdown"
            useWhiteBackground
          >
            <template #content>
              <li
                v-if="allowSnapGrid"
                class="nebula_dropdown__item top-nav__grid-switch"
              >
                <NebulaSwitch
                  is-compressed
                  is-inverted
                  :is-disabled="canvasMode !== 'EDIT'"
                  :label-text="$t('Grid')"
                  class="nebula-dropdown-item__inner"
                >
                  <template #checkbox>
                    <input
                      class="nebula-switch__input"
                      :de-tracking-name="editorSnapGrid
                        ? 'studio_more-options_grid-toggle_on'
                        : 'studio_more-options_grid-toggle_off'"
                      type="checkbox"
                      @input="toggleSnapGrid"
                      :checked="editorSnapGrid"
                      :disabled="canvasMode !== 'EDIT'"
                    >
                  </template>
                </NebulaSwitch>
              </li>
              <li
                v-if="showFullscreenButtonInContextMenu"
                class="nebula-dropdown__item"
              >
                <button
                  class="top-nav__more-option nebula-dropdown-item__inner"
                  de-tracking-name="studio_more-options_fullscreen-button"
                  @click="showFullscreen"
                >
                  <NebulaIcon
                    symbol-id="fullscreen"
                    size="s"
                  />
                  {{ $t('Fullscreen') }}
                </button>
              </li>
              <li
                v-if="showCopyButtonInContextMenu"
                class="nebula-dropdown__item"
              >
                <button
                  data-click-name="Duplicate"
                  data-click-type="Studio Top Navigation"
                  :data-click-experience="experimentCopyJan"
                  :data-click-asset-id="assetId"
                  class="top-nav__more-option nebula-dropdown-item__inner"
                  de-tracking-name="studio_more-options_copy-button"
                  aria-describedby="top-nav__copy-aria-description"
                  @click="openCopyCreationModal"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="copy"
                  />
                  {{ $t('Copy') }}
                  <span
                    id="top-nav__copy-aria-description"
                    class="top-nav__aria-description"
                  >
                    {{ copyText }}
                  </span>
                </button>
              </li>
              <li v-if="showShareButtonInContextMenu">
                <CollaborativeEditable
                  class="top-nav__collaboration-input-wrapper
                    top-nav__collaboration-input-wrapper--sharing-modal
                    nebula-dropdown__item"
                  input-name="sharingModal"
                >
                  <button
                    class="top-nav__more-option nebula-dropdown-item__inner"
                    :class="{
                      'comet-button--warning': draftIsDirty,
                      'comet-button--disabled': shareLocked,
                    }"
                    de-tracking-name="studio_more-options_share-button"
                    :disabled="shareLocked"
                    aria-describedby="top-nav__share-popover"
                    @click="openSharingModal"
                  >
                    <NebulaIcon
                      :symbol-id="draftIsDirty ? 'message-error' : 'share'"
                      size="s"
                    />
                    {{ $t('Share') }}
                  </button>
                </CollaborativeEditable>
                <div
                  id="top-nav__share-popover"
                  class="comet-popover comet-popover--bottom visible top-nav__share-popover"
                >
                  <div class="comet-popover__nub" />
                  {{ shareMessage }}
                  <span class="comet-screenreader-only">
                    {{ $t('Open the Sharing modal') }}
                  </span>
                </div>
              </li>
              <li
                v-if="showEditDraftButton"
                data-click-name="Edit"
                data-click-type="Studio Top Navigation"
                :data-click-experience="experimentCopyJan"
                :data-click-asset-id="assetId"
                class="nebula-dropdown__item"
              >
                <a
                  class="top-nav__more-option nebula-dropdown-item__inner"
                  de-tracking-name="studio_more-options_edit-draft-button"
                  :href="editDraftButtonUrl"
                  aria-describedby="top-nav__edit-aria-description"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="edit"
                  />
                  {{ $t('Edit') }}
                  <span
                    id="top-nav__edit-aria-description"
                    class="top-nav__aria-description"
                  >
                    {{ $t('Edit Draft') }}
                  </span>
                </a>
              </li>
              <li class="nebula-dropdown__item top-nav__print-row">
                <router-link
                  v-if="userIsStudent"
                  data-click-name="Print Preview"
                  data-click-type="Studio Top Navigation"
                  :data-click-experience="experimentCopyJan"
                  :data-click-asset-id="assetId"
                  class="top-nav__more-option  nebula-dropdown-item__inner"
                  de-tracking-name="studio_more-options_print-preview-button"
                  target="_blank"
                  :to="printPreviewLocation"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="print"
                  />
                  {{ $t('Print Preview') }}
                </router-link>
                <button
                  v-else
                  data-click-name="Print Preview"
                  data-click-type="Studio Top Navigation"
                  :data-click-experience="experimentCopyJan"
                  :data-click-asset-id="assetId"
                  class="top-nav__more-option nebula-dropdown-item__inner"
                  de-tracking-name="studio_more-options_print-preview-button"
                  @click="openPrintModal"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="print"
                  />
                  {{ $t('Print Preview') }}
                </button>
              </li>
              <li
                v-if="showDeleteButton"
                class="nebula-dropdown__item top-nav__delete-row"
              >
                <button
                  data-click-name="Delete"
                  data-click-type="Studio Top Navigation"
                  :data-click-experience="experimentCopyJan"
                  :data-click-asset-id="assetId"
                  class="top-nav__more-option nebula-dropdown-item__inner"
                  de-tracking-name="studio_more-options_delete-button"
                  aria-describedby="top-nav__delete-aria-description"
                  @click="openDeleteModal"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="trash"
                  />
                  {{ $t('Delete') }}
                  <span
                    id="top-nav__delete-aria-description"
                    class="top-nav__aria-description"
                  >
                    {{ deleteText }}
                  </span>
                </button>
              </li>
            </template>
          </NebulaDropdown>
        </template>
        <UserMenu v-if="isHomeRoute" />
      </div>
      <template v-if="showBoardOptions">
        <transition
          name="top-nav__page-drawer-transition"
          enter-from-class="top-nav__page-drawer-transition--enter-from"
          leave-to-class="top-nav__page-drawer-transition--leave-to"
        >
          <FocusTrap
            :active="focusTrapActive && !modalIsOpen"
            initial-focus="button,li"
            fallback-focus="button,li"
            @deactivate="onClosePageDrawer"
          >
            <PageDrawer
              v-show="topNavDrawerIsOpen"
              :expanded="topNavDrawerIsOpen"
              :mode="mode"
            />
          </FocusTrap>
        </transition>
      </template>
    </div>
    <TEIMessageBanner
      v-if="isEditingRestricted && pageIsFullSlideTEI && mode === 'EDIT'"
    />
    <ConfirmBackModal
      aria-describedby="confirm-back_modal"
      aria-labelledby="confirm-back_modal-text"
    />
  </header>
</template>

<script>
import { tokens as nebulaTokens } from '@discoveryedu/nebula-core/dist/data/nebula_tokens.json';
import { defineAsyncComponent } from 'vue';
import {
  NebulaIcon,
  NebulaDropdown,
  NebulaButton,
  NebulaSwitch,
} from '@discoveryedu/nebula-components';
import axios from 'axios';
import { FocusTrap } from 'focus-trap-vue';
import fscreen from 'fscreen';
import {
  get,
  isEmpty,
  omit,
} from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import { bus } from '@/lib/eventBus';
import { getLocalData } from '@/lib/localData';
import PageDrawer from '@/components/navigation/PageDrawer.vue';
import PageHeader from '@/components/navigation/PageHeader.vue';
import BoardOptionsPanel from '@/components/navigation/BoardOptionsPanel.vue';
import CollaborativeEditable from '@/components/CollaborativeEditable.vue';
import UserMenu from '@/components/UserMenu.vue';
import ConfirmBackModal from '@/components/modals/ConfirmBackModal.vue';
import chain from '@/lib/utils/chain';
import * as types from '@/lib/constants/store';
import { isMobileOrTablet, isIframe, getBrowserZoomValue } from '@/lib/utils';
import experimentData from '@/mixins/experimentData';
import assignmentMixin from '@/mixins/assignment';
import TEIMessageBanner from '@/components/TEIMessageBanner.vue';
import {
  useAppStore,
  useAssetStore,
  useAssessmentStore,
  useConfirmationStore,
  useEditorStore,
  useErrorStore,
  useModalStore,
  useToastStore,
  useUserStore,
} from '@/stores';
import {
  ASSET_STATUS_ACTIVE,
  EDITOR_SNAP_GRID_CELL_WIDTH,
  EDITOR_SNAP_GRID_CELL_HEIGHT,
  EDITOR_SNAP_GRID_CELL_LARGE_WIDTH,
  EDITOR_SNAP_GRID_CELL_LARGE_HEIGHT,
} from '@/lib/constants';

const CollaboratorDisplay = defineAsyncComponent(() => import(/* webpackChunkName: "edit-mode" */ '@/components/navigation/CollaboratorDisplay.vue'));

export default {
  name: 'TopNav',
  components: {
    CollaborativeEditable,
    CollaboratorDisplay,
    FocusTrap,
    BoardOptionsPanel,
    NebulaIcon,
    NebulaDropdown,
    NebulaButton,
    PageDrawer,
    PageHeader,
    UserMenu,
    NebulaSwitch,
    ConfirmBackModal,
    TEIMessageBanner,
  },
  mixins: [experimentData, assignmentMixin],
  data() {
    return {
      editingName: false,
      focusTrapActive: false,
      tempName: '',
      actionTaken: true,
      action: '',
      isMobileOrTablet,
      zoomPercentage: 100,
      isWaitingToSave: false,
      isBoardOptionsPanelOpen: false,
    };
  },
  created() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState(useEditorStore, [
      'draftType',
      'draftAccessLevel',
      'draftHasTEIErrors',
      'currentUserConnection',
      'isLocked',
      'draftIsDirty',
      'draftIsDeCreated',
      'draftCanBeRestored',
      'draftPointerAssetId',
      'currentModalCanvasId',
      'domains',
      'draft',
      'topNavDropdownIsOpen',
      'topNavDrawerIsOpen',
      'connectedUsers',
      'editorViewModePreview',
      'gridIsDisabled',
      'editorSnapGrid',
      'editorIsFullscreen',
      'saving',
      'isEditingRestricted',
    ]),
    ...mapState(useAssessmentStore, [
      'attempt',
      'attemptIsSubmitted',
      'userCanEditAttempt',
      'pageIsFullSlideTEI',
    ]),
    ...mapState(useAssetStore, [
      'assets',
    ]),
    ...mapState(useErrorStore, [
      'error',
    ]),
    ...mapState(useModalStore, [
      'modals',
      'myContentModalOptions',
    ]),
    ...mapState(useAppStore, [
      'loading',
      'apiToken',
    ]),
    ...mapState(useUserStore, [
      'user',
      'userIsStudent',
      'userCanAssign',
      'isDeUser',
      'isRtl',
    ]),
    backArrowIcon() {
      return this.isRtl ? 'arrow-right' : 'arrow-left';
    },
    isTechbookArtifact() {
      return this.draft?.external_references
        && this.draft.external_references.find((ref) => ref.reference_type === 'concept_page');
    },
    isSmallerWindow() {
      return this.zoomPercentage >= 200 || window.innerWidth <= 900;
    },
    fullscreenText() {
      return this.mode === 'EDIT' ? '' : this.$t('Fullscreen');
    },
    canvasMode() {
      if (this.mode === 'VIEW') return this.mode;
      return this.editorViewModePreview ? 'VIEW' : 'EDIT';
    },
    allowSnapGrid() {
      return this.gridIsDisabled && this.mode === 'EDIT' && !this.draftIsDeCreated;
    },
    showPrevPageButton() {
      const query = get(this, '$route.query', {});

      // if disable_back_button is in the query string, disable
      const { disable_back_button: disableBackButton } = query;

      if (disableBackButton) {
        return false;
      }

      // if there is a source on the url that isn't the canvas redirect url, then enable
      const { source } = query;
      if (source) {
        return !source.includes('iframe-redirect');
      }

      const historyLength = get(window, 'history.length') || 0;
      if (!this.draft || !this.draft.references || historyLength <= 1) {
        return false;
      }

      const referer = window.document.referrer;
      let backIsTheSameArtifact = false;

      // try to determine if back will create a loop
      if (referer) {
        const url = new URL(referer);
        const id = url.searchParams.get('id');

        if (
          url.host === window.location.hostname
          && ['/view', '/edit'].includes(url.pathname)
          && id
        ) {
          const pointerRef = this.draft.references
            .find(ref => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

          const revisionRef = this.draft.references
            .find(ref => ref.reference_type === 'asset' && ref.reference_subtype === 'revision');

          if (
            this.draft.id === id
            || (pointerRef && pointerRef.reference_id === id)
            || (revisionRef && revisionRef.reference_id === id)
          ) {
            backIsTheSameArtifact = true;
          }
        }
      }

      return (
        !this.editorIsFullscreen
        && !backIsTheSameArtifact
      );
    },
    showIframeButton() {
      return isMobileOrTablet() || isIframe();
    },
    allowAttempt() {
      const query = get(this, '$route.query', {});

      return (this.userIsStudent && query.homework_id)
        || (!this.userIsStudent && (query.attempt || query.homework_id));
    },
    assignText() {
      if (this.draftType === 'lesson') return this.$t('Assign Lesson');

      return this.draftType === 'board'
        ? this.$t('Assign Board')
        : this.$t('Assign Slideshow');
    },
    copyText() {
      if (this.draftType === 'lesson') return this.$t('Copy Lesson');

      return this.draftType === 'board'
        ? this.$t('Copy Board')
        : this.$t('Copy Slideshow');
    },
    deleteText() {
      if (this.draftType === 'lesson') return this.$t('Delete Lesson');

      return this.draftType === 'board'
        ? this.$t('Delete Board')
        : this.$t('Delete Slideshow');
    },
    draftRestoreEnabled() {
      return this.draftCanBeRestored && !this.allowAttempt;
    },
    editDraftButtonUrl() {
      if (!this.draft) return null;

      // Continuing to use mongo id until STUD-1048 runs successfully

      // const creationPointerAssetReference = this.draft.references
      //   .find(ref => ref.reference_type === 'asset' && ref.reference_subtype === 'head');
      // const creationPointerAssetId = creationPointerAssetReference
      //   && creationPointerAssetReference.reference_id;

      // return `/edit?id=${creationPointerAssetId || this.draft.id}&page_id=${this.getPageId}`;

      const routePageId = this.$route.query.page_id;
      const pageIdParam = routePageId ? `&page_id=${routePageId}` : '';

      return `/edit?id=${this.draft.id}${pageIdParam}`;
    },
    hideNavShadow() {
      // The top nav has no shadows for the thumbnail generator
      return !!getLocalData();
    },
    isHomeRoute() {
      return ['home', 'published', 'shared-with-me', 'assigned', 'archived',
        'activity-templates', 'trash'].includes(get(this, '$route.name'));
    },
    markedAsComplete() {
      return this.draft && this.draft.assignmentIsComplete;
    },
    modalIsOpen() {
      return this.modals.length > 1 || this.myContentModalIsOpen;
    },
    mode() {
      return this.$route && this.$route.name === 'edit' ? 'EDIT' : 'VIEW';
    },
    myContentModalIsOpen() {
      return get(this, 'myContentModalOptions.config.isActive');
    },
    productBarTitle() {
      if (!this.draftType) return '';

      if (this.draftType === 'lesson') return this.$t('Lesson');

      return this.draftType === 'board' ? this.$t('Board') : this.$t('Slideshow');
    },
    shareMessage() {
      const shares = get(this, 'draft.shares');
      if (!shares) return null;

      let message = '';

      if (this.draftIsDirty) {
        message = this.$t('Share your saved changes');
      } else if (!shares.classroom.length && !shares.group.length && !shares.user.length) {
        message = this.$t('Private – only me');
      } else {
        // Note: If there's an appropriate way to show a detailed message like
        // "Shared with 2 classrooms, 3 users and 1 group" in the future
        // while not having to list all 22 options for singular/plural, then
        // this could become more descriptive
        message = this.$t('Shared with others');
      }

      return message;
    },
    showBoardOptions() {
      return this.$route
        && ['edit', 'view'].includes(this.$route.name)
        && this.draft
        && this.draft.pages
        && !this.error;
    },
    showCollaboratorDisplay() {
      return this.showBoardOptions && this.mode === 'EDIT';
    },
    showAssignContextMenuButton() {
      // Note: When assigning drafts, we won't know the revision asset id
      // until it's published, so it doesn't need to be available here.
      return get(this.$route, 'name') === 'edit'
        && this.userCanAssign;
    },
    showCopyButton() {
      return this.draft
        && this.$route
        && ['edit', 'view'].includes(this.$route.name)
        && ['admin', 'owner', 'edit', 'view'].includes(this.draftAccessLevel);
    },
    showCopyButtonInHeader() {
      // NOTE: Copy button in header has been disabled for now for Editable lessons work.
      // may return later
      return false;
      // return this.showCopyButton
      //   && (!(this.showDeleteButton
      //   || (this.showEditDraftButton && isIframe())
      //   || this.showAssignContextMenuButton
      //   || (['admin', 'owner'].includes(this.draftAccessLevel) && !this.userCanAssign)
      //   || isIframe()
      //   || this.isGrading
      //   ));
    },
    showCopyButtonInContextMenu() {
      return this.showCopyButton && !this.showCopyButtonInHeader;
    },
    showDeleteButton() {
      return ['admin', 'owner'].includes(this.draftAccessLevel);
    },
    showEditDraftButton() {
      return this.showBoardOptions
        && ['admin', 'owner', 'edit'].includes(this.draftAccessLevel)
        && !this.draft.is_deleted
        && this.editDraftButtonUrl
        && this.mode === 'VIEW';
    },
    showMoreOptionsMenu() {
      if (this.userIsStudent && (
        this.userCanEditAttempt
        || this.attemptIsSubmitted)) return false;
      return ['edit', 'view'].includes(get(this, '$route.name')) && !this.isGrading;
    },
    showShareButton() {
      return !this.showDeCreatedDraftButtons
        && this.showBoardOptions
        && !this.draft.is_deleted
        && !this.loading
        && ['admin', 'owner'].includes(this.draftAccessLevel);
    },
    showShareButtonInHeader() {
      // NOTE: share button in header may return in phase 2 of editable lessons
      return false;
      // return this.showShareButton
      // && !(this.mode === 'VIEW')
      // && !(this.showAssignButton && isIframe());
    },
    showShareButtonInContextMenu() {
      return this.showShareButton && !this.showShareButtonInHeader;
    },
    showDeCreatedDraftButtons() {
      return get(this.$route, 'name') === 'edit'
        && this.isDeUser
        && this.draftIsDeCreated;
    },
    showCopyEditLinkButton() {
      return this.draft?.id
        && !this.loading
        && this.showDeCreatedDraftButtons;
    },
    showPublishButton() {
      return this.draft?.id
        && !this.loading
        && !this.draft.is_deleted
        && this.showDeCreatedDraftButtons
        && ['admin', 'owner'].includes(this.draftAccessLevel);
    },
    showSaveAndExitButton() {
      return this.draft?.id
        && !this.loading
        && !this.draft.is_deleted
        && !this.showDeCreatedDraftButtons
        && ['admin', 'owner'].includes(this.draftAccessLevel)
        && this.mode === 'EDIT';
    },
    showFullscreenButton() {
      return fscreen.fullscreenEnabled
        && !this.loading
        && !this.error
        && !this.isGrading
        && !isEmpty(this.draft);
    },
    showFullscreenButtonInHeader() {
      return this.showFullscreenButton
      && !(this.showAssignButton && isIframe())
      && !isMobileOrTablet()
      && !this.isSmallerWindow;
    },
    showFullscreenButtonInContextMenu() {
      return this.showFullscreenButton && !this.showFullscreenButtonInHeader;
    },
    showEmbedButton() {
      const source = get(this, '$route.query.source', '');
      return source.includes('learn/search')
        && !this.userIsStudent
        && isIframe();
    },
    homeUrl() {
      return new URL('/', this.domains.appDomain).toString();
    },
    shareLocked() {
      return this.isLocked('sharingModal');
    },
    printPreviewLocation() {
      const currentQuery = omit(this.$route?.query, ['page_id']);
      return {
        name: 'print-preview',
        query: {
          ...currentQuery,
          type: this.$route.name === 'edit' ? 'draft' : 'creation',
        },
      };
    },
    experimentCopyJan() {
      return JSON.stringify(this.getExperimentData('variant-1-studio-copy-experiment-jan-23'));
    },
    isExperimentCopyJanVariationControl() {
      return this.getExperimentData('variant-1-studio-copy-experiment-jan-23').variation_is_control;
    },
    assetId() {
      return this.draftPointerAssetId();
    },
  },
  methods: {
    ...mapActions(useConfirmationStore, [
      types.CONFIRM_ACTION,
    ]),
    ...mapActions(useAppStore, [
      types.UPDATE_LOADING,
    ]),
    ...mapActions(useEditorStore, [
      types.MARK_AS_COMPLETE,
      types.UPDATE_CURRENTLY_EDITING,
      types.SET_DRAFT_IS_DIRTY,
      types.PUBLISH_DRAFT,
      types.SET_TOP_NAV_DRAWER_IS_OPEN,
      types.SET_EDITOR_SNAP_GRID,
      types.SET_EDITOR_STATUS_MESSAGE,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    onResize() {
      this.zoomPercentage = getBrowserZoomValue();
    },
    toggleSnapGrid() {
      if (this.editorIsFullscreen) return;
      const snapGrid = this.editorSnapGrid ? null : {
        cellWidth: EDITOR_SNAP_GRID_CELL_WIDTH,
        cellHeight: EDITOR_SNAP_GRID_CELL_HEIGHT,
        cellLargeWidthCellCount: EDITOR_SNAP_GRID_CELL_LARGE_WIDTH,
        cellLargeHeightCellCount: EDITOR_SNAP_GRID_CELL_LARGE_HEIGHT,
        lineColor: nebulaTokens.color.platform.neutral['500'],
        showGrid: true,
        z: 99999,
      };
      this[types.SET_EDITOR_SNAP_GRID](snapGrid);
    },
    async goToPrevPage() {
      if (this.mode === 'VIEW') {
        if (!(await this[types.CONFIRM_ACTION]('history:back'))) {
          return;
        }

        const query = get(this, '$route.query', {});

        // if there is a source on the url, then go there
        const { source } = query;
        if (source) {
          // if we're using the legacy 'grade_assignment' source, then just go back
          // to send the user to the assignment page
          if (
            (typeof source === 'string' && source === 'grade_assignment')
            || (Array.isArray(source) && source.includes('grade_assignment'))
          ) {
            window.history.back();
            return;
          }

          document.location.href = source;
          return;
        }

        window.history.back();
      } else {
        document.getElementById('confirm-back-modal').showModal();
      }
    },
    alertAction(a) {
      this.actionTaken = !this.actionTaken;
      if (a === 'copy') {
        if (this.draftType === 'lesson') {
          this.action = this.$t('Lesson copied');
        } else {
          this.action = this.draftType === 'board'
            ? this.$t('Board copied')
            : this.$t('Slideshow copied');
        }
      } else if (this.draftType === 'lesson') {
        this.action = this.$t('Lesson deleted');
      } else {
        this.action = this.draftType === 'board'
          ? this.$t('Board deleted')
          : this.$t('Slideshow deleted');
      }
      this.$nextTick(() => {
        this.actionTaken = !this.actionTaken;
      });
    },
    onBoardOptionsPanelOpen(isOpen) {
      this.isBoardOptionsPanelOpen = isOpen;
      // If the board options panel has been opened, close the page drawer
      // and move focus into the board options panel
      if (isOpen) {
        this[types.SET_TOP_NAV_DRAWER_IS_OPEN](false);

        setTimeout(() => {
          const panelToggleButton = this.$refs?.boardOptionsPanel?.$refs?.toggleButton;
          if (panelToggleButton) panelToggleButton.focus();
        }, 50);
      }
    },
    onClosePageDrawer() {
      if (this.modalIsOpen) return;
      this[types.SET_TOP_NAV_DRAWER_IS_OPEN](false);
    },
    onTogglePageDrawer() {
      this[types.SET_TOP_NAV_DRAWER_IS_OPEN](!this.topNavDrawerIsOpen);
    },
    openCopyCreationModal() {
      this[types.OPEN_MODAL]({
        type: 'CopyCreationModal',
        status: this.mode === 'VIEW' ? 'creation' : 'draft',
        successCallback: () => {
          this.alertAction('copy');
          this.showPanel = false;
        },
      });
    },
    openDeleteModal() {
      this[types.OPEN_MODAL]({
        type: 'ConfirmDeleteBoard',
        draft: this.draft,
        successCallback: async () => {
          await this.$router.push('/'); // on success, route back to the homepage
        },
      });
    },
    openPrintModal() {
      this[types.OPEN_MODAL]({
        type: 'PrintLayoutModal',
      });
    },
    openPublishModal() {
      const draftHasInactiveAssets = chain(this)
        .get('draft.pages', [])
        .map((page) => page.modules.concat(page.notes || []))
        .flatten()
        .filter((mod) => mod.type === 'asset')
        .some((mod) => {
          const asset = this.assets[get(mod, 'options.asset_id')];
          return asset && !asset.error && asset.status?.id !== ASSET_STATUS_ACTIVE;
        })
        .value();
      if (draftHasInactiveAssets) {
        this[types.OPEN_MODAL]({
          type: 'InactiveAssetsModal',
        });
      } else {
        this[types.OPEN_MODAL]({
          type: 'PublishModal',
        });
      }
    },
    async handleSaveAndExitClick() {
      // set loading spinner
      this[types.UPDATE_LOADING](false);

      // if we're saving, we need to wait
      if (this.saving) {
        this.isWaitingToSave = true;
      } else {
        // not waiting? save and exit
        await this.saveAndExit();
      }
    },
    async saveAndExit() {
      try {
        const isDeCreated = this.draftIsDeCreated;

        // publish the darft
        const creation = await this[types.PUBLISH_DRAFT]({ draftId: this.draft.id });

        // grab the asset
        const pointerRef = creation.references
          .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

        if (
          creation.options.type === 'lesson'
          && !creation.sync_info.is_de_created
          && creation.external_references
        ) {
          // redirect to concept page if available
          const conceptPageRef = creation.external_references
            .find((ref) => ref.reference_type === 'concept_page');

          if (conceptPageRef?.reference_id) {
            const url = `/player/${conceptPageRef.reference_id}?lesson_asset_id=${pointerRef.reference_id}`;
            window.location.href = this.domains.appDomain + url;
            return;
          }
        }

        // go to the creation in view mode
        this.$router.replace({
          name: 'view',
          query: {
            id: pointerRef && pointerRef.reference_id,
          },
        });

        const message = isDeCreated ? 'publish-confirmation' : 'save-confirmation';
        this[types.SET_EDITOR_STATUS_MESSAGE]({ message, duration: 15000 });
      } catch (e) {
        this[types.UPDATE_LOADING](false);
        const errorMessage = get(e, 'response.data.meta.message', e);
        this[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
        throw e;
      }
    },
    copyEditLink() {
      const input = document.createElement('input');
      input.style.position = 'absolute';
      input.style.left = '-1000px';
      document.body.appendChild(input);

      const draftPointerReference = this.draft.references
        .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

      input.value = `${window.location.origin}/edit?id=${draftPointerReference && draftPointerReference.reference_id}`;
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this[types.SET_STUDIO_TOAST]({
        type: 'success',
        position: 'top',
        message: this.$t('Link copied!'),
      });
    },
    openSharingModal() {
      if (this.mode === 'EDIT') {
        this[types.UPDATE_CURRENTLY_EDITING]({ sharingModal: this.currentUserConnection?.id });
      }
      // For drafts, the sharing modal publishes;
      // for published creations, it updates shares
      const sharingMode = this.mode === 'VIEW' ? 'UPDATE' : 'PUBLISH';

      // If we have TEI errors, they won't be saved once we publish. Confirm first
      if (this.mode === 'EDIT' && this.draftHasTEIErrors) {
        this[types.OPEN_MODAL]({
          type: 'TEIErrorModal',
          successCallback: () => {
            this[types.OPEN_MODAL]({
              mode: sharingMode,
              type: 'SharingModal',
            });
          },
        });
        return;
      }

      this[types.OPEN_MODAL]({
        mode: sharingMode,
        type: 'SharingModal',
      });
    },
    showFullscreen() {
      bus.emit('requestFullscreen');
    },
    onPageDrawerOverlayClick() {
      if (!this.topNavDropdownIsOpen) this.onTogglePageDrawer();
    },
    embedAsset() {
      const url = `${this.domains.appDomain}/lti/gradable-asset/${this.assetId}`;

      axios.get(url, {
        withCredentials: true,
        crossDomain: true,
      })
        .then((response) => {
          if (response.data.is_gradable) {
            this.postSelectedAssessment(this.assetId, response.data.token);
          } else {
            this.getEmbedUrl(this.assetId);
          }
        })
        .catch((error) => {
          const errorMessage = get(error, 'response.data.meta.message', error);
          this[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
        });
    },
    async postSelectedAssessment(assetId, token) {
      const url = `${this.domains.appDomain}/lti/select-assessment`;

      try {
        const formData = new FormData();
        formData.append('asset_guid', assetId);
        const headers = {
          'X-Token': token,
        };
        const options = {
          method: 'POST',
          url,
          data: formData,
          headers,
          responseType: 'json',
          withCredentials: true,
        };
        const response = await axios(options);
        window.location.href = response.data.redirect_url;
      } catch (error) {
        const errorMessage = get(error, 'response.data.meta.message', error);
        this[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
      }
    },
    getEmbedUrl(assetId) {
      const url = `${this.domains.appDomain}/lti/get-embed-url/${assetId}`;

      axios.get(url, {
        withCredentials: true,
        crossDomain: true,
      })
        .then((response) => {
          window.location.href = response.data.embed_url;
        })
        .catch((error) => {
          const errorMessage = get(error, 'response.data.meta.message', error);
          this[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
        });
    },
  },
  watch: {
    topNavDrawerIsOpen(isOpen) {
      // If the page drawer was collapsed, kill the focus trap immediately.
      // Otherwise wait until the page drawer is visible to activate it
      if (!isOpen) {
        this.focusTrapActive = false;
      } else {
        this.$nextTick(() => {
          this.focusTrapActive = true;
        });
      }
    },
    saving(isSaving) {
      // just finished saving and we're waiting to save and exit?
      if (!isSaving && this.isWaitingToSave) {
        // set is waiting back to false
        this.isWaitingToSave = false;

        // continue with save and exit
        this.saveAndExit();
      }
    },
  },
};
</script>

<style lang="stylus">
  @import '../../styles/top-nav.styl';
</style>
