<template>
  <div
    class="page-header"
    v-if="pages"
  >
    <button
      type="button"
      class="page-header__nav-button page-header__nav-button--prev"
      de-tracking-name="studio_top-nav_previous-side-button"
      :disabled="currentPageIndex === 0"
      @click="goToPrevPage"
    >
      <NebulaIcon
        symbol-id="caret-left"
        size="s"
      />
      <span class="comet-screenreader-only">
        {{
          draftType === 'board'
            ? $t('Previous Page')
            : $t('Previous Slide')
        }}
      </span>
    </button>
    <button
      type="button"
      class="page-header__page-button"
      :de-tracking-name="pageDrawerExpanded
        ? 'studio_top-nav_page-drawer-toggle_open'
        : 'studio_top-nav_page-drawer-toggle_close'"
      :aria-expanded="pageDrawerExpanded.toString()"
      @click="$emit('togglePageDrawer')"
    >
      <span class="page-header__page-button-text">
        {{ pageHeading }}
      </span>
      <span class="comet-screenreader-only">
        {{ pageDrawerExpanded ? $t('Close') : $t('Open') }} {{ pageLabel }}
      </span>
      <NebulaIcon
        :symbol-id="pageDrawerExpanded ? 'caret-up' : 'caret-down'"
        size="s"
      />
    </button>
    <button
      type="button"
      class="page-header__nav-button page-header__nav-button--next"
      de-tracking-name="studio_top-nav_next-side-button"
      :disabled="currentPageIndex === pages.length - 1"
      @click="goToNextPage"
    >
      <NebulaIcon
        symbol-id="caret-right"
        size="s"
      />
      <span class="comet-screenreader-only">
        {{
          draftType === 'board'
            ? $t('Next Page')
            : $t('Next Slide')
        }}
      </span>
    </button>
  </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { mapActions, mapState } from 'pinia';
import {
  useAssessmentStore,
  useEditorStore,
  useModalStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  name: 'PageHeader',
  components: {
    NebulaIcon,
  },
  props: {
    pageDrawerExpanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['togglePageDrawer'],
  computed: {
    ...mapState(useEditorStore, [
      'draft',
      'currentPage',
      'draftType',
      'currentPageIndex',
      'draftHasTEIErrors',
      'pagesSorted',
    ]),
    ...mapState(useAssessmentStore, [
      'unsubmittedAttempt',
    ]),
    pages() {
      return this.pagesSorted;
    },
    pageHeading() {
      const totalPages = this.pages.length;
      const l10nOpts = {
        currentPage: this.currentPageIndex + 1,
        totalPages,
      };

      if (this.draftType === 'board') {
        return this.$t('Page %(currentPage)s of %(totalPages)s', l10nOpts);
      }

      return this.$t('Slide %(currentPage)s of %(totalPages)s', l10nOpts);
    },
    pageLabel() {
      return this.draftType === 'board' ? this.$t('Page drawer') : this.$t('Slide drawer');
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.VIEW_PREV_PAGE,
      types.VIEW_NEXT_PAGE,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    goToPrevPage() {
      if (this.draftHasTEIErrors) {
        this[types.OPEN_MODAL]({
          type: 'TEIErrorModal',
          successCallback: () => {
            this[types.VIEW_PREV_PAGE]();
          },
        });
        return;
      }

      // Confirm before navigating if we have an unsubmitted attempt
      if (this.unsubmittedAttempt) {
        this[types.OPEN_MODAL]({
          type: 'UnsavedAnswersModal',
          successCallback: () => {
            this[types.VIEW_PREV_PAGE]();
          },
        });
        return;
      }

      this[types.VIEW_PREV_PAGE]();
    },
    goToNextPage() {
      if (this.draftHasTEIErrors) {
        this[types.OPEN_MODAL]({
          type: 'TEIErrorModal',
          successCallback: () => {
            this[types.VIEW_NEXT_PAGE]();
          },
        });
        return;
      }

      // Confirm before navigating if we have an unsubmitted attempt
      if (this.unsubmittedAttempt) {
        this[types.OPEN_MODAL]({
          type: 'UnsavedAnswersModal',
          successCallback: () => {
            this[types.VIEW_NEXT_PAGE]();
          },
        });
        return;
      }

      this[types.VIEW_NEXT_PAGE]();
    },
  },
};
</script>

<style lang="stylus">
.page-header {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);

  .page-header__nav-button {
    background: $nebula-color-white;
    border: 1px solid $nebula-color-platform-interactive-800;
    margin: 0;
    padding: $nebula-space-1x;
    transition: background-color $nebula-transition-default;
    border-radius: 50%;

    svg {
      display: block;
      fill: $nebula-color-platform-interactive-800;
    }

    &[disabled] {
      border-color: $comet-text-color-disabled

      svg {
        fill: $comet-text-color-disabled;
      }
    }

    &:not([disabled]) {
      &:hover, &:focus {
        background: $nebula-color-platform-interactive-200;
      }
    }

  }

  .page-header__page-button {
    align-items: center;
    background: $nebula-color-white;
    border: none;
    border-radius: 0;
    color: $nebula-color-platform-interactive-800;
    display: flex;
    font-size: $nebula-font-size-body-2;
    gap: $nebula-space-1x;
    line-height: 1;
    margin: 0 1px;
    padding: $nebula-space-1x $nebula-space-2x;
    text-align: center;
    transition: background-color $nebula-transition-default;

    &:hover, &:focus {
      background: $nebula-color-platform-interactive-200;
    }

    .page-header__page-button-text {
      font-weight: 600;
      max-width: 200px;
      overflow: hidden;
      padding: $nebula-space-half 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      fill: $nebula-color-platform-interactive-800;
    }
  }

  html[dir="rtl"] & {
    .page-header__nav-button {
      &--prev {
        border-radius: 0 $nebula-border-radius-badge-default $nebula-border-radius-badge-default 0;
      }

      &--next {
        border-radius: $nebula-border-radius-badge-default 0 0 $nebula-border-radius-badge-default;
      }

      svg {
        transform: scaleX(-1);
      }
    }
  }
}
</style>
