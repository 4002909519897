import { createApp, markRaw } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import nebulaSvg from '@discoveryedu/nebula-icons/nebula.svg';
import I18NextVue from 'i18next-vue';
import { createPinia } from 'pinia';
import { VUE_APP_GTM_ID } from '@/lib/constants/app';
import i18next from './lib/i18next';
import StudioApp from './StudioApp.vue';
import { createRouter } from './router';
import { bus } from './lib/eventBus';
import {
  getDomains,
  getRegion,
  getEnv,
} from './lib/api';

/**
 * Starts to give us the ability to bootstrap an app anywhere we want.
 * Puts the init function to start everything up into window.studio.init
 * @param {Object} options Options object used to initialize the app
 * @param {Object} options.api Studio api client
 * @param {Object} options.apiProgressBar Studio api client with progress bar
 * @param {Object} options.deApi App api client
 * @param {Object} options.contentApi Content api client
 * @param {Object} options.domains Object with domains used by app
 * @param {string} options.env Current environment running
 * @param {string} options.region Current region
 */
export default async function initialize(options) {
  // add the bus to the window
  window.studio = {
    /**
     * run it!
     *
     * @param target css selector where the app will be loaded
     * @param appComponentOptions misc options to pass to the application
     */
    init: (target, appComponentOptions) => {
      const app = createApp(
        StudioApp,
        {
          options: appComponentOptions,
        },
      );

      const pinia = createPinia();

      // Make our Axios clients available to all Pinia stores
      function PiniaApisPlugin({ store: piniaStore }) {
        if (piniaStore.$id.startsWith('studio-')) {
          return {
            api: markRaw(options.api),
            apiProgressBar: markRaw(options.apiProgressBar),
            deApi: markRaw(options.deApi),
            contentApi: markRaw(options.contentApi),
          };
        }
      }
      pinia.use(PiniaApisPlugin);

      // Make environment settings available to all studio Pinia stores
      function PiniaEnvPlugin({ store: piniaStore }) {
        if (piniaStore.$id.startsWith('studio-')) {
          return {
            domains: options.domains || getDomains(),
            env: options.env || getEnv(),
            region: options.region || getRegion(),
            studioAppIsRunning: true,
          };
        }
      }
      pinia.use(PiniaEnvPlugin);

      // attach apis
      app.config.globalProperties.$api = options.api;
      app.config.globalProperties.$apiProgressBar = options.apiProgressBar;

      // no de api? use the titan api
      app.config.globalProperties.$deApi = options.deApi || options.api;

      app.config.globalProperties.$contentApi = options.contentApi;

      // attach app url
      app.config.globalProperties.$appDomain = options.domains.appDomain;

      // Create the router
      const router = createRouter(pinia);
      app.use(router);

      // add vue gtm for every env
      app.use(createGtm({
        id: VUE_APP_GTM_ID,
        enabled: false, // disable GTM until we've loaded user info
        debug: false,
        loadScript: true,
        vueRouter: router,
      }));

      // Connect templating filters
      app.config.globalProperties.$nebulaSVGPath = nebulaSvg;
      app.config.globalProperties.$windowLocation = {

        get() {
          return window.location;
        },
      };

      // Prep l10n and create Vue instance. Actual l10n files are loaded in the UPDATE_L10N
      // action after the first handshake with the edde server, so we know what our locale is
      app.use(I18NextVue, { i18next });

      app.use(pinia);

      app.mount(target);
    },
    bus,
    /**
     * External apps can listen to events
     */
    listen: (eventName, callback) => {
      bus.on(eventName, callback);
    },
  };
}
