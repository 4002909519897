<template>
  <div
    class="board-options"
    v-if="draft && draft.pages"
  >
    <component
      :is="isInteractive ? 'button' : 'div'"
      ref="toggleButton"
      class="board-options__toggle"
      :class="customClasses"
      :aria-expanded="isInteractive && showPanel.toString()"
      :aria-describedby="isInteractive && 'board-options__aria-description'"
      @click="isInteractive ? onClickToggle() : undefined"
      @keydown="isInteractive ? focusOutReverse($event) : undefined"
    >
      <span
        id="board-options__aria-description"
        class="board-options__aria-description"
      >
        {{ toggleDescription }}
      </span>
      <div class="board-options__toggle-text">
        <div class="board-options__button-name-wrapper">
          <span
            v-if="!isMobileOrTablet()"
            class="board-options__button-name"
          >
            {{ draftName }}
          </span>
          <NebulaIcon
            v-if="isMobileOrTablet() && !restricted"
            class="board-options__info-icon"
            symbol-id="information"
          />
        </div>
        <div class="board-options__saving-wrapper">
          <span
            class="board-options__saving-icon board-options__saving-icon--saving"
            v-if="saving"
          >
            <LoadingIndicator
              v-if="!isMobileOrTablet() && !restricted"
              small
              transparent
            />
          </span>
          <span v-if="mode === 'EDIT' && !isMobileOrTablet() && !restricted">
            <span
              v-if="!saving"
              class="board-options__saving-icon board-options__saving-icon--saved"
            >
              <NebulaIcon
                symbol-id="check"
                size="s"
              />
            </span>
            <span class="board-options__saving-text">{{ statusText }}</span>
            <span
              class="board-options__draft-pill"
            >
              {{ $t('Draft') }}
            </span>
          </span>
        </div>
      </div>
      <NebulaIcon
        v-if="isInteractive && !restricted"
        class="board-options__toggle-icon"
        :symbol-id="`caret-${showPanel ? 'up' : 'down'}`"
        size="s"
      />
    </component>
    <transition
      name="board-options__panel-transition"
      enter-from-class="board-options__panel-transition--enter-from"
      enter-active-class="board-options__panel-transition--enter-active"
      leave-active-class="board-options__panel-transition--leave-active"
      leave-to-class="board-options__panel-transition--leave-to"
    >
      <div
        v-if="showPanel"
        class="board-options__panel"
      >
        <CollaborativeEditable
          class="board-options__collaboration-input-wrapper"
          input-name="boardTitle"
        >
          <form
            v-if="editingName"
            ref="boardNameForm"
            class="board-options__name-wrapper"
            @submit.prevent="confirmName"
          >
            <NebulaInput
              ref="editNameInput"
              class="board-options__name-input"
              :text="tempName"
              type="text"
              :disabled="isLocked('boardTitle')"
              @input="tempName = $event"
              @keydown.escape="confirmName"
            />
            <button
              ref="clearButton"
              :aria-label="boardLabelClear"
              type="button"
              :disabled="isLocked('boardTitle')"
              class="board-options__clear-name-button"
              @click="clearName"
            >
              <NebulaIcon
                symbol-id="x"
                size="s"
              />
            </button>
          </form>
          <div
            v-else
            class="board-options__name-wrapper"
          >
            <span class="board-options__board-name-text">
              {{ draftName }}
            </span>
            <button
              :aria-label="boardLabelEdit"
              type="button"
              :disabled="isLocked('boardTitle')"
              class="board-options__edit-name-button"
              de-tracking-name="studio_board-options_rename-button"
              @click="beginEditingName()"
              v-if="mode === 'EDIT'"
            >
              <NebulaIcon
                symbol-id="edit"
                size="s"
              />
            </button>
          </div>
        </CollaborativeEditable>

        <hr class="board-options__divider">

        <p class="board-options__meta">
          {{ $t('Created: %(date)s by %(fullName)s', {
            date: draftCreatedDate,
            fullName: createdByName,
            interpolation: { escapeValue: false },
          }) }}
          <br>
          {{ $t("Last updated") }}: {{ draftUpdatedDate }}
        </p>

        <a
          v-if="contentUrl"
          :href="contentUrl"
          class="comet-button comet-button--primary board-options__content-button"
          de-tracking-name="studio_board-options_view-in-atlas-button"
          target="_blank"
        >
          {{ $t('View in Atlas') }}
        </a>
        <a
          v-if="sharedVersionUrl"
          :href="sharedVersionUrl"
          class="comet-button comet-button--primary board-options__content-button"
          :de-tracking-name="shareMessage.tracking"
          target="_blank"
        >
          {{ shareMessage.message }}
        </a>

        <hr class="board-options__divider">

        <CollaborativeEditable
          class="board-options__collaboration-input-wrapper"
          input-name="boardDescription"
        >
          <NebulaFieldGroup
            input-id="board-options-description"
            :label-text="$t('Add a description')"
          >
            <template #formElement>
              <NebulaTextarea
                id="board-options-description"
                class="board-options__description"
                :disabled="mode === 'VIEW' || isLocked('boardDescription')"
                :placeholder="placeholderText"
                :text="draftDescription"
                @input="draftDescription = $event"
                @keydown="focusOutForward"
                @focus="onInputFocusChanged('boardDescription', true)"
                @blur="onInputFocusChanged('boardDescription', false)"
              />
            </template>
          </NebulaFieldGroup>
        </CollaborativeEditable>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  NebulaFieldGroup, NebulaIcon, NebulaInput, NebulaTextarea,
} from '@discoveryedu/nebula-components';
import { get, trim, truncate } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import { bus } from '@/lib/eventBus';
import { decodeHTML, formatDate, isMobileOrTablet } from '@/lib/utils';
import * as appConstants from '@/lib/constants/app';
import * as types from '@/lib/constants/store';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import CollaborativeEditable from '@/components/CollaborativeEditable.vue';
import { getEnv } from '@/lib/api';
import {
  ROLE_STUDENT,
  ROLE_TEACHER,
  ROLE_ACCOUNT_ADMIN,
  ROLE_MULTI_ACCOUNT_ADMIN,
  ROLE_SITE_ADMIN,
} from '@/lib/constants';
import {
  useEditorStore,
  useModalStore,
  useUserStore,
} from '@/stores';

export default {
  name: 'BoardOptionsPanel',
  components: {
    LoadingIndicator,
    CollaborativeEditable,
    NebulaFieldGroup,
    NebulaIcon,
    NebulaInput,
    NebulaTextarea,
  },
  props: {
    mode: {
      type: String, // out of ['EDIT', 'VIEW']
      required: true,
    },
    isInteractive: {
      type: Boolean,
      default: true,
    },
    restricted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['panel-open'],
  data() {
    return {
      editingName: false,
      savingEllipsis: '',
      savingEllipsisInterval: null,
      showPanel: false,
      tempName: '',
      isMobileOrTablet,
    };
  },
  computed: {
    ...mapState(useEditorStore, [
      'draft',
      'saving',
      'draftAssetId',
      'draftType',
      'currentlyEditing',
      'isLocked',
      'currentUserConnection',
      'draftIsDeCreated',
      'domains',
    ]),
    ...mapState(useUserStore, [
      'isDeUser',
      'userIsStudent',
      'userHasAnyRole',
    ]),
    shareMessage() {
      const isLesson = this.draftType === 'lesson';
      return {
        message: isLesson ? this.$t('View Saved Version') : this.$t('View Shared Version'),
        tracking: isLesson ? 'studio_board-options_view-saved-version-button' : 'studio_board-options_view-shared-version-button',
      };
    },
    customClasses() {
      return { isInteractive: 'board-options__toggle--interactive', restricted: 'board-options__toggle--expanded' };
    },
    createdByName() {
      if (!this.draft) return null;
      const showCreatedByDe = this.draftIsDeCreated
        && this.userHasAnyRole([
          ROLE_STUDENT,
          ROLE_TEACHER,
          ROLE_ACCOUNT_ADMIN,
          ROLE_MULTI_ACCOUNT_ADMIN,
          ROLE_SITE_ADMIN,
        ]);
      return showCreatedByDe
        ? this.$t('Discovery Education')
        : `${this.draft.owner_first_name} ${this.draft.owner_last_name}`;
    },
    boardLabelClear() {
      if (this.draftType === 'lesson') {
        return this.$t('Clear lesson name');
      }

      return this.draftType === 'board'
        ? this.$t('Clear board name')
        : this.$t('Clear slideshow name');
    },
    boardLabelEdit() {
      if (this.draftType === 'lesson') {
        return this.$t('Edit lesson name');
      }

      return this.draftType === 'board'
        ? this.$t('Edit board name')
        : this.$t('Edit slideshow name');
    },
    contentUrl() {
      // Only editors can view content.de.com for published assets
      if (!this.isDeUser || !this.draftAssetId()
        || get(this.$route, 'name') !== 'view') return null;

      // Studio content is authored in the US site rather than in a region-specific content site
      const currentEnv = getEnv();
      const appDomainUS = appConstants[`VUE_APP_${currentEnv}_APPURL_US`];

      // Create a url like https://atlas.discoveryeducation.com/assets/<BOARD_ID>
      return appDomainUS.replace('app', 'atlas').replace('learn', `assets/${this.draftAssetId()}`);
    },
    draftCreatedDate() {
      if (!this.draft || !this.draft.metadata || !this.draft.metadata.created_on) return '';

      return formatDate(this.draft.metadata.created_on);
    },
    draftDescription: {
      get() {
        return this.draft && this.draft.options && decodeHTML(this.draft.options.description);
      },
      set(newDescription) {
        this[types.UPDATE_DRAFT_DESCRIPTION](newDescription);
      },
    },
    draftName() {
      if (!this.draft.options) return '';

      // Board names are escaped using HTML entities on the BE. Decode them (e.g. &amp; => &)
      if (this.draft.name) return decodeHTML(this.draft.name);

      let typeName = this.draftType === 'board' ? this.$t('New Board') : this.$t('New Slideshow');

      if (this.draftType === 'lesson') {
        typeName = this.$t('New Lesson');
      }

      return typeName;
    },
    draftUpdatedDate() {
      if (!this.draft || !this.draft.metadata || !this.draft.metadata.updated_on) return '';

      return formatDate(this.draft.metadata.updated_on, true);
    },
    placeholderText() {
      if (this.draftType === 'lesson') {
        return this.$t('This lesson is about...');
      }

      return this.draftType === 'board'
        ? this.$t('This board is about...')
        : this.$t('This slideshow is about...');
    },
    sharedVersionUrl() {
      if (!get(this.draft, 'metadata.published_on') || get(this.$route, 'name') !== 'edit') return null;

      if (
        this.draft.options.type === 'lesson'
        && !this.draft.sync_info.is_de_created
        && this.draft.external_references
      ) {
        // grab the asset
        const pointerRef = this.draft.references
          .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

        // redirect to concept page if available
        const conceptPageRef = this.draft.external_references
          .find((ref) => ref.reference_type === 'concept_page');

        if (conceptPageRef?.reference_id) {
          const url = `/player/${conceptPageRef.reference_id}?lesson_asset_id=${pointerRef.reference_id}`;
          return this.domains.appDomain + url;
        }
      }

      // Not all creations have a pointer asset right now, so this is on hold until STUD-1119.

      // const creationPointerAssetReference = this.draft.references
      //   .find(ref => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

      // return `/view?id=${creationPointerAssetReference
      //   && creationPointerAssetReference.reference_id}`;

      return `/view?id=${this.draft.id}`;
    },
    statusText() {
      const saving = this.$t('Saving');
      const saved = this.$t('Saved');
      const text = `${this.saving ? saving : saved}${this.savingEllipsis}`;

      return text;
    },
    toggleDescription() {
      if (this.draftType === 'lesson') {
        return this.$t('Lesson options panel');
      }

      return this.draftType === 'board'
        ? this.$t('Board options panel')
        : this.$t('Slideshow options panel');
    },
  },
  watch: {
    saving(saving) {
      // This is a watch rather than a computed property since it depends on its own interval.
      if (!saving) {
        clearInterval(this.savingEllipsisInterval);
        this.savingEllipsis = '';
      } else {
        this.savingEllipsis = '...';
        this.savingEllipsisInterval = setInterval(() => {
          if (this.savingEllipsis === '...') this.savingEllipsis = '.';
          else this.savingEllipsis += '.';
        }, 500);
      }
    },
    showPanel(newVal) {
      this.$emit('panel-open', newVal);
    },
    editingName() {
      if (this.mode === 'EDIT') {
        if (this.editingName) {
          this.onInputFocusChanged('boardTitle', true);
        } else {
          this.onInputFocusChanged('boardTitle', false);
        }
      }
    },
  },
  mounted() {
    bus.on('document:mousedown', this.onBodyClick);
  },
  beforeUnmount() {
    bus.off('document:mousedown', this.onBodyClick);
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.UPDATE_DRAFT_NAME,
      types.UPDATE_DRAFT_DESCRIPTION,
      types.UPDATE_CURRENTLY_EDITING,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    focusOutReverse(e) {
      if (e.shiftKey && e.key === 'Tab' && this.showPanel) {
        this.showPanel = !this.showPanel;
      }
    },
    focusOutForward(e) {
      if (!e.shiftKey && e.key === 'Tab' && this.showPanel) {
        this.showPanel = !this.showPanel;
      }
    },
    beginEditingName() {
      // Populate our temporary name and focus the input
      this.tempName = decodeHTML(this.draft.name);
      this.editingName = true;
      this.$nextTick(function focusInput() {
        if (this.$refs.editNameInput) {
          this.$refs.editNameInput.$el.focus();
        }
      });
    },
    confirmName() {
      let newName = this.tempName || '';

      if (!trim(newName)) {
        // If we've cleared out the name, give it the default name
        // based on its created timestamp
        const dateString = formatDate(get(this.draft, 'metadata.created_on'), true);

        let typeLabel = this.draftType === 'board' ? this.$t('Board') : this.$t('Slideshow');
        if (this.draftType === 'lesson') typeLabel = this.$t('Lesson');

        newName = `${typeLabel} ${dateString}`;
      }

      if (newName.length >= 100) {
        // Prevent board names longer than 100 characters, since those will
        // have trouble being published or assigned
        newName = truncate(newName, { length: 100 });
      }

      // Save the edited name
      this[types.UPDATE_DRAFT_NAME](newName);
      this.editingName = false;
    },
    clearName() {
      this.tempName = '';
      if (this.$refs.editNameInput) {
        this.$refs.editNameInput.$el.focus();
      }
    },
    onBodyClick({ target }) {
      // Prevent false positives from elements that are immediately removed
      // from the DOM (e.g. the edit title button)
      if (!document.body.contains(target)) return;

      // If we've clicked away from the name input, close and save it
      if (this.$refs.boardNameForm && !this.$refs.boardNameForm.contains(target)) {
        this.confirmName();
      }

      // Close the panel if we click outside of it (except if one of its modals is open)
      if (!this.$el.contains(target) && !target.closest('.modal--base')) {
        this.showPanel = false;
      }
    },
    onClickToggle() {
      this.showPanel = !this.showPanel;
    },
    onInputFocusChanged(inputName, isFocused) {
      if (this.mode === 'EDIT') {
        if (isFocused && !this.isLocked(inputName)) {
          this[types.UPDATE_CURRENTLY_EDITING]({ [inputName]: this.currentUserConnection?.id });
        } else if (!this.isLocked(inputName)) {
          this[types.UPDATE_CURRENTLY_EDITING]({ [inputName]: null });
        }
      }
    },
  },
};
</script>

<style lang="stylus">
.board-options {
  height: 100%;

  &__collaboration-input-wrapper {
    border-radius: $nebula-border-radius-badge-default;
    margin-bottom: $nebula-space-2x;
    position: relative;

    .nebula-field-group {
      margin-bottom: 0;
    }
  }

  &__toggle { // .board-options__toggle
    align-items: center;
    background-color: transparent;
    border: 0;
    border-left: solid 1px $nebula-color-interface-blue-100;
    border-radius: 0;
    display: flex;
    font-size: $nebula-font-size-body-2;
    height: 100%;
    margin: 0;
    max-width: 180px;
    padding: 0 $nebula-space-2x;
    text-align: start;
    transition: background-color $nebula-transition-default;

    &--interactive:hover,
    &--interactive:focus {
      background-color: $nebula-color-platform-interactive-200;
    }

    &--expanded {
      max-width: 200px;
    }

    @media only screen and (min-width: $comet-breakpoints-tablet-landscape) {
      max-width: 210px;
    }

    @media only screen and (min-width: $comet-breakpoints-tablet-wide-landscape) {
      max-width: 300px;
    }
  }

  &__aria-description {
    display: none;
  }

  &__button-name-wrapper {
    display: flex;
    margin-bottom: $nebula-space-half;
  }

  &__button-name {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__info-icon {
    fill: $nebula-color-white;
  }

  &__draft-pill {
    background: $comet-color-yellow-60;
    border-radius: $nebula-space-2x;
    color: $comet-text-color-primary-default;
    display: inline-block;
    padding: 0 $nebula-space-1x;
  }

  &__toggle-text { // .board-options__toggle-text
    align-items: normal; // allow children to use ellipsis overflow
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1px;
  }

  &__saving-wrapper {
    align-items: center;
    display: flex;
  }

  &__saving-icon {
    line-height: 0;
    margin-right: $nebula-space-1x;
  }

  &__saving-wrapper &__saving-icon--saving .comet-spinner .comet-spinner__icon {
    height: $nebula-space-2x;
    width: $nebula-space-2x;
  }

  &__saving-wrapper &__saving-icon--saved {
    fill: $comet-color-green-60;
  }

  &__saving-text { // .board-options__saving-text
    margin-inline-end: $nebula-space-1x;
    color: $comet-color-neutral-65;
    font-size: $nebula-font-size-caption;
    font-weight: bold;
  }

  &__toggle-icon { // .board-options__toggle-icon
    fill: $nebula-color-platform-interactive-800;
    flex-shrink: 0;
    margin-left: $nebula-space-2x;
  }

  &__panel { // .board-options__panel
    background-color: $nebula-color-white;
    border-radius: $nebula-border-radius-badge-default;
    box-shadow: $comet-shadow-z-shadow-3;
    color: $comet-text-color-primary-on-light;
    padding: $nebula-space-2x;
    position: absolute;
    top: "calc(100% + %s)" % $nebula-space-1x;
    width: 450px;
    z-index: -8;
  }

  &__name-wrapper { // .board-options__name-wrapper
    align-items: center;
    display: flex;
    position: relative;
  }

  &__name-input.nebula-input {
    font-size: $nebula-font-size-body-2;
    margin: 0;
    padding-inline-end: $nebula-space-4x;
  }

  &__clear-name-button { // .board-options__clear-name-button
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: $nebula-space-1x;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      fill: $comet-color-interactive-default;
    }
  }

  &__edit-name-button { // .board-options__edit-name-button
    background: none;
    border: 0;
    border-radius: 0;
    line-height: 0;
    margin: 0 $nebula-space-1x 0 0;
    padding: $nebula-space-half;

    svg {
      fill: $comet-text-color-secondary-on-dark;
    }
  }

  &__board-name-text { //  .board-options__board-name-text
    font-size: $nebula-font-size-body-1;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__divider { // .board-options__divider
    border: 0;
    border-bottom: $comet-border-hairline-default;
    margin: 0 0 $nebula-space-2x 0;
  }

  &__meta { // .board-options__meta
    color: $comet-color-neutral-50;
    font-size: $nebula-font-size-caption;
    font-weight: bold;
    margin: 0 0 $nebula-space-2x 0;
  }

  &__content-button.comet-button {
    margin: 0 0 $nebula-space-2x 0;
  }

  &__description.nebula-textarea {
    min-height: $nebula-space-8x;
    resize: vertical;
  }

  .board-options__panel-transition--enter-active {
    transition: $nebula-transition-default;
  }
  .board-options__panel-transition--leave-active {
    transition: $nebula-transition-default;
  }

  .board-options__panel-transition--enter-from,
  .board-options__panel-transition--leave-to {
    transform: translateY(-120%);
  }

  html[dir="rtl"] & {
    &__saving-icon {
      margin-left: $nebula-space-1x;
      margin-right: 0;
    }

    &__toggle-icon {
      margin-left: 0;
      margin-right: $nebula-space-2x;
    }

    &__clear-name-button {
      left: 0;
      right: auto;
    }

    &__edit-name-button {
      margin: 0 0 0 $nebula-space-1x;
    }
  }
}
</style>
